* {
	margin: 0px;
	padding: 0;

  }



  .container10 {
    padding-top: 120px;
    padding-bottom: 120px;
    text-align: center;
    justify-content: center;
    background-color: rgb(0, 0, 0);
  }

  .container20 {
    padding-top: 120px;
    padding-bottom: 377px;
    text-align: center;
    
    background-color: rgb(48, 47, 47);
  }
  
  


  .container11 {
    padding-top: 120px;
    padding-bottom: 120px;
    text-align: center;
    justify-content: center;
    background-color: rgb(87, 85, 85);
  }
  


  .container13 {
    padding-top: 120px;
    padding-bottom: 120px;
    text-align: center;
    justify-content: center;
    background-color: black;
  }
  

  .container15 {
    padding-top: 120px;
    padding-bottom: 120px;
    text-align: center;
    justify-content: center;
    background-color: rgb(87, 85, 85);
  }
