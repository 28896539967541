.App {
    text-align: center;
  }
  
  .App-logo {
    height: 30vmin;
    pointer-events: none;
  }

  .middle-center{
    .middle-center {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
  }
  }

